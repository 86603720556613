@import './assets/css/formiojs.css';
@import './assets/css/dynamicform.css';
@import './assets/css/bootstrap-grid.min.css';

@layer tailwind-base, primereact, tailwind-utilities;
@import '~primeicons/primeicons.css';

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
    margin: 0;
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5rem;
}

@media screen and (min-width: 1280px) {
    html,
    body {
        font-size: 12px;
    }
}

@media screen and (min-width: 1300px) {
    html,
    body {
        font-size: 14px;
    }
}

@media screen and (min-width: 1920px) {
    html,
    body {
        font-size: 16px;
    }
}

.p-multiselect-filter {
    @apply !h-8 !p-0 !px-2 !shadow-none;
}

@layer base {
    *[disabled] {
        @apply opacity-25;
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    h5 {
        @apply text-lg;
    }

    h6 {
        @apply text-base;
    }

    .text-base {
        line-height: 1;
    }

    p {
        @apply text-sm;
        line-height: 1;
    }

    span {
        @apply text-sm;
        line-height: 1;
    }

    li {
        @apply text-base;
    }

    th {
        @apply text-base;
    }

    td {
        @apply text-lg;
    }

    label {
        @apply text-base;
    }

    html,
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    input,
    span:not(.pi),
    th,
    tr,
    td,
    label,
    ul,
    li,
    p,
    div {
        font-family: 'Barlow', sans-serif !important;
    }
}

table tr td {
    a {
        @apply !cursor-pointer;
    }
}

table tr th span[data-pc-section='sort'] {
    @apply !cursor-pointer;
}

.boxShadow {
    box-shadow: 0 0 8px 0px #999;
}

.text-xs {
    font-size: 1rem;
}

.text-sm {
    font-size: 1rem;
}

.text-xxs {
    @apply text-[1rem] leading-4;
}

.button-primary {
    @apply text-white font-medium bg-primary hover:bg-primary-200;
}

.button-primary-outline {
    @apply btn-outline text-primary border-primary hover:bg-primary-50 hover:text-primary hover:border-primary;
}

.button-primary-loading-spinner {
    @apply text-base font-medium text-white border-primary bg-primary hover:bg-primary-200;

    span {
        @apply loading loading-spinner;
    }
}

.button-primary-icon {
    @apply text-white font-medium bg-primary hover:bg-primary-200;
}

.button-neutral {
    @apply bg-neutral-100/50 border-neutral-100 hover:bg-neutral-100 hover:border-neutral;
}

.button-neutral-outline {
    @apply btn-outline text-neutral-200 bg-neutral font-medium border-neutral-100 hover:text-neutral-400 hover:border-neutral-200 hover:bg-neutral;
}

.button-neutral-icon {
    @apply font-medium bg-neutral border-neutral-100 hover:bg-neutral-100 hover:border-neutral-200;
}

.loginBg {
    background: url('./assets/images/loginBg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

* {
    scrollbar-color: inherit;
}

::-webkit-scrollbar {
    height: 0.6rem;
    width: 0.6rem;
}

::-webkit-scrollbar-thumb {
    @apply bg-neutral-100;
    border-radius: 5px;
}
.toggle_disable_State {
    .toggle:disabled {
        --togglehandleborder: 0 0 0 inset !important;
        opacity: 0.2;
    }
}

div[aria-checked='false'][data-pc-name='inputswitch'] {
    [data-pc-section='slider'] {
        @apply border-2 border-neutral-200 bg-white before:bg-neutral-200 before:left-[0.15rem] before:top-[60%];
    }
}
div[aria-checked='true'][data-pc-name='inputswitch'] {
    [data-pc-section='slider'] {
        @apply border-2 border-primary bg-white before:bg-primary before:left-[0.35rem] before:top-[60%];
    }
}

div[data-p-disabled='true'][data-pc-name='dropdown'] {
    @apply cursor-not-allowed pointer-events-auto;

    [data-pc-section='input'] {
        @apply cursor-not-allowed;
    }
    [data-pc-section='trigger'] {
        @apply cursor-not-allowed;
    }
}

[data-pc-section='box'] {
    [data-pc-section='checkbox.icon'] {
        @apply text-white;
    }
    [data-pc-section='headercheckbox.icon'] {
        @apply text-white;
    }
    [data-pc-section='rowcheckbox.icon'] {
        @apply text-white;
    }
}
/* Remove up and down arrows in Chrome, Safari, Edge, and Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove up and down arrows in Firefox */
.no-arrows[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

table thead[data-pc-section='thead'] {
    /* freeze table header - prime table */
    @apply sticky top-0 z-[3];
}

table tr[data-pc-section='rowexpansion'] {
    /* applied rowexpand bg color */
    @apply bg-neutral-800;
}

.tableComponent tr {
    @apply focus:outline-none !important;
}

.tableComponent tr th,
.tableComponent tr td {
    @apply align-middle border-[1.6px] border-neutral-50/50 !important;
}

.tableComponent tr th:not([class*='sticky']),
.tableComponent tr td:not([class*='sticky']) {
    right: -1px !important;
}

.customTable thead {
    position: sticky;
    top: 0;
}

.tableComponent tr th {
    @apply bg-inherit py-4 !important;
}

.tableComponent tr .sticky {
    z-index: 1;
    border-left: 0px;
    border-right: 0px;
}

.tableComponent tr .sticky:before {
    @apply border-neutral-50/50 w-full h-full absolute left-0 top-0 border-x;
    content: '';
}

.tableComponent tr .sticky > * {
    @apply relative z-[1];
}

.tableComponent tr:nth-child(2) th {
    background-color: #f3f3f3 !important;
    @apply py-2 !important;
}

.createOrderQuoteSelection {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
    background: white !important;
}

.createOrderQuoteSelection.create-contact,
.create-contact {
    height: calc(100vh - 123px);
}

.progressStepperHeight {
    max-height: calc(100vh - 121px);
    overflow-y: auto;
}

@media only screen and (min-width: 1920px) {
    .createOrderQuoteSelection.create-contact,
    .create-contact {
        height: calc(100vh - 145px);
        overflow-y: auto;
    }
}

@media only screen and (max-width: 1400px) {
    .createOrderQuoteSelection.create-contact,
    .create-contact {
        height: calc(100vh - 103px);
        overflow-y: auto;
    }
}

@media only screen and (max-width: 767px) {
    .createOrderQuoteSelection.create-contact,
    .create-contact {
        height: calc(100vh - 157px);
        overflow-y: auto;
    }
}

/* for currency exchange table */

.tableCurrency tr {
    td:nth-child(4) {
        @apply border-b-0 border-x-0 border-x-transparent pl-1 text-right flex flex-row-reverse !important;
    }

    td:nth-child(5) {
        @apply border-l-0 border-l-transparent p-0 !important;
    }

    th:nth-child(5) {
        @apply bg-red-200;
    }
}

.tableCurrency tr:nth-child(1) {
    th:nth-child(4) {
        @apply hidden;
    }
}

.tableCurrency thead tr:nth-child(2) {
    @apply bg-[#f3f3f3];
}

.tableCurrency tr:nth-child(2) {
    th:nth-child(4) {
        @apply border-0 !important;
    }

    th:nth-child(5) {
        @apply border-l-0 !important;
    }
}

.tableSecondColumnPadding {
    tbody tr {
        td:nth-child(2) {
            @apply p-2 !important;
        }
    }
}

.btn {
    font-weight: 500;
    line-height: 1;
    font-size: 1rem;
}

.btn-sm {
    height: 2.5rem;
    font-size: 14px;
}

/* Formio custom classes */
.custom-count-label {
    @apply text-lg text-primary font-medium mb-4;
}

#popup_menu_right,
#popup_menu_right * {
    @apply bg-white !important;
}

#popup_menu_right li:hover * {
    @apply bg-neutral !important;
}

dialog::before {
    content: '';
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
}

.api-loader:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
}

.lds-ellipsis {
    display: none;
    position: fixed;
    width: 80px;
    height: 80px;
    left: calc(50% - 40px);
    top: 50%;
    z-index: 999999;
}

.api-loader .lds-ellipsis {
    display: block;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.ToastPopupResponsive {
    top: 50px !important;
    z-index: 999999;
}

@media (max-width: 640px) {
    .ToastPopupResponsive {
        right: 50% !important;
        transform: translate(50%, -2%) !important;
    }
}

.registration-form {
    .loginBg::after {
        position: relative;
        content: '';
        position: absolute;
        height: 10px;
        width: 100%;
        bottom: 0px;
        left: 0px;
        background: hsla(357, 85%, 52%, 1);
        background: linear-gradient(
            45deg,
            hsla(357, 85%, 52%, 1) 0%,
            hsla(357, 84%, 52%, 1) 0%,
            hsla(206, 84%, 41%, 1) 100%
        );
        background: -moz-linear-gradient(
            45deg,
            hsla(357, 85%, 52%, 1) 0%,
            hsla(357, 84%, 52%, 1) 0%,
            hsla(206, 84%, 41%, 1) 100%
        );
        background: -webkit-linear-gradient(
            45deg,
            hsla(357, 85%, 52%, 1) 0%,
            hsla(357, 84%, 52%, 1) 0%,
            hsla(206, 84%, 41%, 1) 100%
        );
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EC1C25", endColorstr="#EB1D26", GradientType=1 );
    }

    @media screen and (max-width: 1024px) {
        .registration-form-container {
            align-items: flex-start;
        }
    }

    @media screen and (max-width: 1200px) {
        .copyright {
            bottom: 9px;
        }
    }

    h3 {
        font-size: 24px !important;
        line-height: 2.3rem;
    }

    p {
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
    }
}

/* Calender popup filter width */
div[aria-label='Choose Date'] {
    max-width: 300px;
    @apply rounded-md;

    td[data-p-today='true'] {
        span {
            @apply border border-neutral-200;
        }
    }
    td[data-pc-section='day'] {
        @apply p-0.5;
    }
    th[data-pc-section='tableheadercell'] {
        @apply p-0.5;
    }
    td[data-p-other-month='true'] {
        @apply text-neutral-200/50;
    }
}

.calender-input {
    .pi.pi-calendar {
        @apply relative;
    }
    .pi.pi-calendar:before {
        @apply opacity-0;
    }

    .pi.pi-calendar::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: -2px;
        width: 20px;
        height: 20px;
        background-image: url('assets/images/date-picker.svg');
        background-position: top;
        background-size: 14px;
        background-repeat: no-repeat;
        object-fit: fill;
        opacity: 0.7;
    }
}

.calender-input:has(input[aria-expanded='true']) {
    @apply border border-primary;
}

.taskTables .collapse-arrow > .collapse-title:after {
    transform: rotate(-45deg);
}

.taskTables .collapse-arrow:not(.collapse-close) > input:checked ~ .collapse-title:after {
    transform: rotate(45deg);
}

/* Summary Page Table */
#summary-list tr th,
#summary-list tr {
    @apply bg-primary-light-50;
}

#summary-country-list-frozen > div:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

#summary-country-list-scrollable > div:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

#summary-listing-tabPanel > div:first-child {
    border-top-right-radius: 0.3rem;
}

#summary-listing-tabPanel > div:last-child {
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

/* formio custom class - patent details EP */
.patentDetailsEpSectionHeader p {
    @apply text-lg text-primary font-medium pb-3;
}

.patentDetailsEpSectionInfo {
    @apply min-h-0;

    label {
        @apply text-sm text-black font-medium;

        span {
            @apply !text-black;
        }
    }

    input {
        @apply !ml-0;
    }

    .form-check-label {
        @apply gap-x-2;
    }
}

.patentDetailsEpSectionIfYes .card::before {
    content: '';
    @apply p-[6px] absolute top-[-7px] left-8 border border-neutral-100 bg-[#F4F6F7] rotate-45 border-r-0 border-b-0;
}

.patentDetailsEpSectionIfYes {
    .card {
        @apply relative mt-2 p-6 border border-neutral-100 bg-neutral-100/15 rounded-md w-full lg:w-2/3;
    }

    .languageSelections div {
        @apply flex gap-2 mb-1;
    }

    .formio-component-htmlelement {
        @apply text-sm text-neutral-700 font-medium;
    }

    .formio-component-htmlelement span {
        @apply text-[#eb0000];
    }

    .row {
        @apply gap-x-4;
    }

    .form-check.checkbox .form-check-label {
        @apply flex-row gap-x-2;

        span {
            @apply text-black font-medium;
        }
    }

    .formio-component-checkbox {
        @apply !min-h-0 mt-3;
    }

    .form-check.checkbox .form-check-label .form-check-input {
        outline: none !important;
        @apply h-[1.10rem] w-[1.10rem] text-lg;
    }

    .formio-component-datetime .form-control {
        @apply w-full md:w-5/12 bg-white;
    }

    .formio-component-datetime {
        label {
            @apply font-medium text-sm;
        }
    }

    .card.card-body {
        @apply !mb-0;
    }
}

.epClaimTranslationValidation {
    .patentDetailsEpSectionIfYes {
        @apply mt-6;
    }
}

.patentDetailsEpSectionDate {
    .formio-errors {
        @apply md:w-5/12;
    }
}

div[data-pc-name='checkbox'] div[data-p-disabled='true'] {
    background: #d5dbe194;
}

div[data-pc-name='checkbox'] div[data-p-disabled='true'][data-p-highlight='true'] {
    background: #1d8ade;
}

.prefixedLinkedUrl {
    .input-group {
        @apply relative;
    }

    .input-group-prepend {
        @apply absolute px-[10px] flex items-center h-full font-medium bg-neutral-100/50 border border-neutral-100 rounded-s-md;
    }

    .form-control {
        @apply pl-[73px];
    }
}

.suffixedLinkedUrl {
    .input-group {
        @apply relative;
        display: flex;
    }

    .input-group-append {
        @apply flex items-center ml-2;
    }
}

.orderOrQuotePopup {
    .descriptionConfirmationPopup {
        @apply font-medium;
    }

    .yesButton {
        @apply px-4;
    }
}

.orderOrQuotePopupHideCancelButton {
    .descriptionConfirmationPopup {
        @apply font-medium;
    }

    .yesButton {
        @apply px-4;
    }

    .noButton {
        @apply hidden;
    }
}

.addLanguageButton {
    .formio-component-button {
        @apply !inline;
    }
}

.orderdetail-upload-sourcedocument {
    .uploadSection {
        @apply w-full;
    }

    .customStyle {
        @apply px-4 py-1;
    }

    .documentSection {
        @apply border-0 mt-0 p-[0.4rem];
    }
}

.stdFileUpload {
    .customStyle {
        @apply p-0;
    }
    .documentSection {
        @apply bg-transparent p-0 mt-0 border-0;
    }

    .documentUploadSection {
        @apply !m-0 !my-0 !mt-2;
    }

    .uploadSection {
        @apply !w-full;
    }
}
div[data-testid='std-dataTable-component'] {
    .tableCustomHeight {
        @apply !min-h-0;
    }
    .tableComponent {
        @apply p-0;
    }
}

.view-task-detail-file-upload {
    .documentSection {
        @apply lg:w-2/4;
    }

    .uploadSection {
        @apply w-full;
    }
}

.orderdetail-upload-fileddocument {
    .uploadSection {
        @apply w-full;
    }

    .documentSection {
        @apply border-0 mt-0 p-2;
    }

    .customStyle {
        @apply pb-0 px-3 pt-1;
    }
}

.inlineFilter input {
    @apply h-8;
}

.orderdetail-upload-formaldocument {
    .uploadSection {
        @apply w-full;
    }

    .documentSection {
        @apply border-0 mt-0 pb-0;
    }

    .customStyle {
        @apply px-2 py-0;
    }
}

.suffixLinkedUrl {
    .input-group {
        @apply relative flex;
    }

    input {
        @apply !mt-0;
        border-right-style: hidden;
        border-radius: 0.375rem 0px 0px 0.375rem !important;
        padding: auto;
    }

    .input-group-append {
        @apply px-[10px] flex items-center font-medium h-9;
        background-color: white;
        height: 2.5rem;
        border-width: 0.063rem;
        border-left-style: hidden;
        border-right-style: hidden;
        @apply !border-neutral-100;
    }
}

.breakDownForm {
    .formio-component-number {
        min-height: 50px;
    }

    div:nth-child(2) {
        input {
            @apply !mt-0;
            @apply !border-neutral-200;
            border-radius: 0px 5px 5px 0px !important;
            border-width: 0.063rem !important;
            background-color: #fafbfc !important;
        }
    }

    input:focus {
        @apply !border-neutral-100;
    }
}

.breakDownForm > div:first-child {
    padding-right: unset !important;
}

.breakDownForm > div:first-child + div {
    padding: unset !important;
}

.breakDownHeader {
    @apply mb-2 mt-2;
    @apply text-sm font-medium leading-5 text-neutral-700 block;
}

.breakDownTitle {
    @apply mb-2;
}

.multipleLanguageGrid {
    background-color: white;
    margin-bottom: 20px;

    .btn {
        @apply text-white;
    }

    .btn-danger {
        @apply text-neutral-200 border-0 shadow-none;
    }

    .col-form-label {
        @apply !pb-4 text-sm text-primary;
    }

    .editgrid-table-head {
        @apply border-t-2 border-t-primary;
        @apply shadow-md shadow-slate-200 bg-inherit;

        td {
            @apply !border border-neutral-100 !border-r;
        }

        td:nth-child(1) {
            @apply w-1/5;
        }
        tr {
            @apply border-0;
        }

        tr:nth-child(1) {
            @apply !border-b-0;
        }
    }

    .table :where(th, td) {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .editgrid-table-column {
        border-right: 1px solid;
        @apply divide-y divide-slate-200 text-base font-medium text-neutral-200 !border-neutral-100 border;
    }

    .editgrid-table-body {
        @apply border-0;

        tr {
            @apply bg-white even:bg-neutral hover:bg-neutral-50 border-neutral-100;
        }

        .editgrid-table-column {
            @apply border border-neutral-100 border-r;
        }

        tr {
            @apply border border-neutral-100;

            td:nth-child(2) {
                @apply gap-1 flex-wrap;

                span {
                    @apply text-[#11334E] bg-pale-blue border-soft-blue border rounded-md p-2 !important;
                }
            }
        }
    }
}

.formalDocumentUpload {
    .formio-component-form {
        @apply !p-0;
    }

    .statusCustomStyle {
        @apply p-5;
    }

    .CustomColumnStyles {
        .card.card-body {
            @apply p-5;
        }

        .statusCustomStyle {
            @apply p-0;
        }
    }

    .formio-component-airwayNo {
        @apply p-4;
    }

    .formalDocumentFields-Form {
        @apply mr-2;
    }
}

@media screen and (max-width: 640px) {
    .formalDocumentUpload {
        @apply overflow-x-hidden overflow-y-scroll max-h-48;
    }
}
.tableCustomHeight {
    min-height: calc(100vh - 167px);
    max-height: calc(100vh - 167px);
}

.tableCustomMaxHeight {
    max-height: calc(100vh - 167px);
}

@media screen and (min-width: 1536px) {
    .tableCustomHeight {
        min-height: calc(100vh - 190px);
        max-height: calc(100vh - 190px);
    }

    .tableCustomMaxHeight {
        max-height: calc(100vh - 190px);
    }
}

@media screen and (min-width: 1920px) {
    .tableCustomHeight {
        min-height: calc(100vh - 210px);
        max-height: calc(100vh - 210px);
    }

    .tableCustomMaxHeight {
        max-height: calc(100vh - 210px);
    }
}

.formalDocumentDropdownItemsHidden[data-p-disabled='true'] {
    display: none;
}

.filedDocumentDropdownItemsHidden[data-p-disabled='true'] {
    display: none;
}

.customCheckbox {
    position: absolute;
    top: -20px;
    right: 10px;
}

.checkbox-custom-style {
    span {
        @apply text-neutral-700;
    }

    input[name='data[serviceAgreement]'] {
        @apply ml-8;
    }

    input[name='data[isAgreeTMDiscounts]'] {
        @apply ml-5;
    }
}

.radio-column {
    @apply mb-[7.5rem] mt-[0.5rem];
}

.radio-column .form-radio {
    @apply flex-col mt-0;
}

.custom-count-label-top {
    @apply mt-[1rem];
}

.filedDocumentStatusPopup {
    .formio-component-rejectionReason {
        @apply p-5 pt-3;
    }
}

.VendorRateListTableStyle,
.vendoruserProfilePageTableStyle {
    .tableComponent {
        @apply !p-0;
    }
}

.contactUserTableStyle {
    .tableComponent {
        @apply !p-0 !pt-4;
    }
}

.custom-lookup div[ref='nested-container'] {
    @apply flex items-center justify-center;
}

.custom-lookup div[ref='nested-container'] .company-text-field {
    @apply flex-1;
}

.custom-lookup div[ref='nested-container'] .search-icon i {
    @apply text-[1.35rem] mt-[-0.5rem] rounded-[50%] ml-[1rem] cursor-pointer;
}

.cutomizedPopupTableStyle {
    @apply py-3;

    table {
        @apply w-full;
    }

    table tr {
        @apply border border-neutral-100 p-2 bg-white;
    }

    table tr th,
    table tr td {
        @apply p-2;
    }

    table tr th {
        @apply text-start font-normal text-neutral-1000;
    }

    table tr td {
        @apply font-medium !text-base;
    }

    table tr:nth-child(1) {
        @apply border-0 !bg-inherit;
    }
}

.ratesTabView {
    .tableCustomHeight {
        min-height: calc(100vh - 314px) !important;
        max-height: calc(100vh - 314px) !important;
    }

    @media screen and (min-width: 1536px) {
        .tableCustomHeight {
            min-height: calc(100vh - 367px) !important;
            max-height: calc(100vh - 367px) !important;
        }
    }

    @media screen and (min-width: 1920px) {
        .tableCustomHeight {
            min-height: calc(100vh - 420px) !important;
            max-height: calc(100vh - 420px) !important;
        }
    }
}

.customInputNumber {
    max-width: 6rem;
    border: 1px solid #d5dbe1;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

div[data-pc-name='tieredmenu'] {
    @apply w-fit;
}
li[data-pc-section='emptymessage'] {
    @apply ml-2;
}

.remove-cursor-pointer table tbody,
.remove-cursor-pointer table tbody tr,
.remove-cursor-pointer table tbody tr td {
    cursor: default !important;
}

.taskDetailVendorBreakDown {
    .tableComponent {
        @apply !pt-0 !px-0;
    }

    .headerCustomStyle {
        @apply hidden;
    }
}

.ql-hidden {
    visibility: hidden;
}

.ql-toolbar.ql-snow .ql-formats:has(.ql-size),
.ql-toolbar.ql-snow .ql-formats .ql-strike,
.ql-toolbar.ql-snow .ql-formats .ql-script,
.ql-toolbar.ql-snow .ql-formats .ql-clean,
.ql-toolbar.ql-snow .ql-formats .ql-indent,
.ql-toolbar.ql-snow .ql-formats .ql-blockquote,
.ql-toolbar.ql-snow .ql-formats .ql-video,
.ql-toolbar.ql-snow .ql-formats .ql-formula,
.ql-toolbar.ql-snow .ql-formats .ql-source,
.ql-toolbar.ql-snow .ql-formats .ql-image {
    display: none;
}

.languageSelectionTable {
    .tableCustomHeight {
        min-height: calc(100vh - 224px) !important;
        max-height: calc(100vh - 224px) !important;
    }

    @media screen and (min-width: 1536px) {
        .tableCustomHeight {
            min-height: calc(100vh - 260px) !important;
            max-height: calc(100vh - 260px) !important;
        }
    }

    @media screen and (min-width: 1920px) {
        .tableCustomHeight {
            min-height: calc(100vh - 298px) !important;
            max-height: calc(100vh - 298px) !important;
        }
    }
}

.toggle:disabled {
    --togglehandleborder: 0 0 0 inset !important;
}
[data-pc-section='filterinput'] {
    [data-pc-name='inputtext'] {
        @apply h-8 rounded-md w-40 focus:shadow-none font-medium text-black;
    }
}
